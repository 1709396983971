import React, { useState } from 'react'
import cx from 'classnames'
import { connect } from '@picostate/react'

import Footer from '@/footer.js'
import * as checkout from '../api/checkout.js'
import { Close } from '@/svgs.js'

const CheckoutItem = ({ item }) => {
  const [currentQty, setQuantity] = useState(item.quantity)
  const handleQuantity = (quantity) => {
    checkout.update({
      id: item.id,
      quantity
    })
    setQuantity(quantity)
  }
  return (
    <div className='checkout__items my1 checkout__spacing f jcb ais s22'>
      <div className='f jcs ais'>
        <img src={item.smallImage} alt={item.title} />
        <div className=''>
          <span className='s22 ml1 checkout__items-title'>{item.title}</span>
          <span className='ml1 block checkout__items-size'>{item.variant.title}</span>
        </div>
      </div>
      <div className='checkout__price'>
        ${item.variant.price}
      </div>
      <div>
        <div className='f jcs aic product-qty'>
          <div className='product-qty-select f jcc aic' onClick={() => handleQuantity(currentQty - 1)}>
            <span className='minus' />
          </div>
          <input defaultValue={1} value={currentQty} onChange={e => handleQuantity(e.currentTarget.value)} name='quantity' minValue='1' className='cw product__qty ac s22 p1 mx1' />
          <div className='product-qty-select f jcc aic' onClick={() => handleQuantity(currentQty + 1)}>
            <span className='plus' />
          </div>
        </div>
      </div>
      <div className='f jcb aic checkout__sub'>
        <span>${item.variant.price * currentQty}</span>
        <div onClick={() => handleQuantity(0)}><Close /></div>
      </div>
    </div>
  )
}

const CheckoutData = connect(state => ({
  cart: state.checkout
}))(({cart}) => (
  <div>
    <div className='checkout__header checkout__spacing f jcb aic s22'>
      <div className=''>Item</div>
      <div className=''>Price</div>
      <div className=''>Quantity</div>
      <div className=''>Subtotal</div>
    </div>
    {cart && cart.lineItems.map(item => (
      <CheckoutItem item={item} key={item.id} />
    ))}
    {cart && cart.lineItems.length >= 1 && (
      <div className='checkout__footer ar f jce fw'>
        <div className='ar'>
          <span className='s22'>${cart.totalPrice}</span>
          <a href={cart.webUrl} className='h1 mt05 pt0 block'>Checkout</a>
        </div>
      </div>
    )}
  </div>
))

const Checkout = ({ transitionStatus, cart }) => {
  return (
    <div className={cx('animate__page checkout', transitionStatus)}>
      <div className='nav__push' />
      <div className='container--xl ma x p1'>
        <h1>Bag</h1>
      </div>
        <div className='container--xl p1'>
          <CheckoutData />
        </div>
      <Footer />
    </div>
  )
}

export default Checkout
